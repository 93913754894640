import React from 'react';
import ReactDOM from 'react-dom/client';
import "@/assets/styles/rest.css";
import "@/assets/styles/flex.scss"
import "@/assets/styles/_var.scss"
import App from './App';
import reportWebVitals from './reportWebVitals';
import  axios from '@/api/http.js';
import { Provider } from "react-redux"
import store from "@/store"
import 'lib-flexible'
React.Component.prototype.$https=axios;
React.Component.prototype.$store=store;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
// store.subscribe(()=>{
//   root.render(<App />)
// })
    // <React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
