import { lazy, Suspense, useEffect } from 'react';
import { Navigate, useNavigate} from 'react-router-dom';
import { SpinLoading } from 'antd-mobile'
import { useRoutesWithMiddleware } from 'react-router-middleware-plus';
const _import = (path) => lazy(() => import("@/pages/" + path));
const Components = (componentName) => {
  const WrapComponent = _import(`${componentName}`);
  return (<Suspense fallback={
    <div style={{position:'fixed',top:'50%',left:'50%',transform:'translate(-50%,-50%)',}}>
      <SpinLoading style={{ '--size': '1.3rem', '--color':'#298871'}}  />
    </div>}>
    <WrapComponent></WrapComponent>
  </Suspense>)
}

const CheckLogin = ({children}) => {
  const navigate = useNavigate();
  // 获取登录信息
  const isLogin = !!localStorage.getItem('token');
  useEffect(() => {
    if (!isLogin) {
      // navigate('/login', {
      //   replace: true
      // })
      navigate('/404', {
        replace: true
      })
    }
  }, [isLogin])
  
  return isLogin?children:null;
}

const Routes = () => {
  const routes = [
    {
      path: '/',
      key:"redirect",
      element: <Navigate to="/extension" replace={true} />
    },
    {
      path:'/',
      key:'home',
      element:Components('home/home'),
      children:[
        {
          path: 'extension',
          key: 'extension',
          meta: {
            title: '注册我玩我家'
          },
          // middleware中callback从左到右依次执行
          middleware: [CheckLogin],
          element: Components('extension/extension')
        },
        {
          path: 'mine',
          key: 'mine',
          meta: {
            title: '注册我玩我家'
          },
          middleware: [CheckLogin],
          element: Components('mine/mine')
        }
      ]
    },
    {
      path:'/mineInvitation',
      key: 'mineInvitation',
      meta: {
        title: '“我玩我家”推广收益系统'
      },
      middleware: [CheckLogin],
      element: Components('mineInvitation/mineInvitation')
    },
    {
      path:'/login',
      key:'login',
      meta:{
        title:'“我玩我家”推广收益系统'
      },
      middleware: [CheckLogin],
      element: Components('login/login')
    },
    {
      path: '/register',
      key: 'register',
      meta: {
        title: '注册我玩我家'
      },
      middleware: [CheckLogin],
      element: Components('register/register')
    },
    {
      path: '/registerSuccess',
      key: 'registerSuccess',
      meta: {
        title: '注册我玩我家'
      },
      middleware: [CheckLogin],
      element: Components('registerSuccess/registerSuccess')
    },
    {
      path: '/joinGroup',
      key: 'joinGroup',
      middleware: [CheckLogin],
      meta: {
        title: '参与拼团'
      },
      element: Components('joinGroup/joinGroup')
    },
    {
      path: '/paySuccess',
      key: 'paySuccess',
      meta: {
        title: '支付成功'
      },
      middleware: [CheckLogin],
      element: Components('paySuccess/paySuccess')
    },
    {
      path: '/joinSuccess',
      key: 'joinSuccess',
      meta: {
        title: '加盟成功'
      },
      middleware: [CheckLogin],
      element: Components('joinSuccess/joinSuccess')
    },
    {
      path: '/joinError',
      key: 'joinError',
      meta: {
        title: '加盟失敗'
      },
      middleware: [CheckLogin],
      element: Components('joinError/joinError')
    },
    {
      path: '/pay',
      key: 'pay',
      meta: {
        title: '支付'
      },
      middleware: [CheckLogin],
      element: Components('pay/pay')
    },
    { 
      path: '*',
      key:'all', 
      element: Components('404') 
    },
  ]
  return useRoutesWithMiddleware(routes);
}
export default Routes;