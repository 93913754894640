// 导入封装好的axios实例
import request from './axiosConfig';
const http = {
  /**
   * methods: 请求
   * @param url 请求地址
   * @param params 请求参数
   */
  get(url, params, type='api') {
    const config = {
      method: 'get',
      url: '/' + type + url,
      urlType: type,
    };
    if (params) {
      config.params = params;
    }
    return request(config);
  },
  post(url, params, type = 'api') {
    const config = {
      method: 'post',
      url: '/' + type + url,
      urlType: type,
    };
    if (params) config.data = params;
    return request(config);
  }
};
//导出
export default http;
