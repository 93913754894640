const initState={
  groupInfo: {},
  userInfo: {},
  cardList: {},
  qrCodeUrl: (params) => {
    if (process.env.NODE_ENV == 'development') {
      // return 'http://192.168.0.19:8080'+params;
      return 'https://ehomeh5-test.edsmall.com' + params;
    } else {
      if (window.location.origin.indexOf('-test') > -1) {
        return 'https://ehomeh5-test.edsmall.com' + params;
      } else {
        return 'https://ehomeh5.edsjia.com' + params;
      }
    }
  },
}
const extensionReducer = (preState = initState, actions) => {
  const {type,data}=actions;
  //修改数据的type类型
  switch (type) {
    case 'ActionsCardList':
      preState.cardList=data;
      return preState;
    case 'ActionsGroupInfo':
      preState.groupInfo = data;
      return preState;
    default:
      return preState
  }
}
export default extensionReducer;