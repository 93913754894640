import localStorage from "localStorage";
export const userAgentObj = () => {
  const ua = navigator.userAgent.toLowerCase()
  let isWeiXin = false,
    isDingTalk = false,
    isApp = false,
    obj = {}
  if (ua.match(/DingTalk/i) === "dingtalk") {//用钉钉打开
    isDingTalk = true
  } else if (ua.match(/MicroMessenger/i) === "micromessenger") {//用微信打开
    isWeiXin = true
  } else {//用其他浏览器打开
    isApp = true
  }
  obj.isWeiXin = isWeiXin
  obj.isDingTalk = isDingTalk
  obj.isApp = isApp
  localStorage.setItem("userAgentObj", JSON.stringify(obj))
  return obj
}
export function countDown(time) {
  var nowTime = +new Date();
  var inTime = +new Date(time.replace(/-/g, '/'))//返回的是用户输入时间总的毫秒数
  var secondTime = (inTime - nowTime) / 1000;//剩余时间总的秒数
  if (secondTime <= 0) {
    return { d: '00', h: '00', m: '00', s: '00' }
  }
  var d = parseInt(secondTime / 60 / 60 / 24);//计算天数
  d = d < 10 ? '0' + d : d;//当天数小于10时补0
  var h = parseInt(secondTime / 60 / 60 % 24);//计算小时
  h = h < 10 ? '0' + h : h;
  var m = parseInt(secondTime / 60 % 60);  //计算分
  m = m < 10 ? '0' + m : m;
  var s = parseInt(secondTime % 60);       //计算当前秒数
  s = s < 10 ? '0' + s : s;
  return { d, h, m, s }
}

export const _saveStorage = (key, value) => {
  let val = typeof (value) == 'string' ? value : JSON.stringify(value)
  localStorage.setItem(key, val);
}
export const _getStorage = (key) => {
  let val = localStorage.getItem(key)
  let checkType = (value) => {
    try {
      JSON.parse(value)
      return true
    } catch (err) {
      return false
    }
  }

  if (val != null) {
    return checkType(val) ? JSON.parse(val) : val
  }
}
export const _removeStorage = (key) => {
  localStorage.removeItem(key)
}

