import { legacy_createStore, combineReducers, applyMiddleware } from 'redux'     

import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension'

/**
 * 定义一个Reducer ，Reducer是一个方法，有两个参数
 * state 保存redux中的数据
 * action是方法在视图中通过dispatch调用，通过返回值修改state中数据
 * */
import extension from "./modules/extension/reducer";
//可以合并多个模块actions
const Reducer = combineReducers({
  extension,
})
//创建存储对象并且抛出对象
const store = legacy_createStore(Reducer, composeWithDevTools(applyMiddleware(thunk)));
export default store;//抛出store对象的信息