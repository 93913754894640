import axios from 'axios';  //引入axios
import {Toast } from 'antd-mobile';
import { _getStorage, _removeStorage } from "@/utils/index"
//创建axios对象
const instance = axios.create({
  timeout: 20000,
  headers: {
    'Content-Type': "application/json",
  },
});
// instance.defaults.base_activeTest_url = "https://ehomeh5-test.edsmall.com";
// instance.defaults.base_active_url = "https://ehomeh5.edsjia.com";
// instance.defaults.base_officialTest_url = "http://192.168.0.183:30372";
// instance.defaults.base_official_url = "http://192.168.0.183:30372";
// instance.defaults.base_sceneTest_url = "https://cjd-test.edsmall.com";
// instance.defaults.base_scene_url = "https://cjd.edsjia.com";

// http request 拦截器
instance.interceptors.request.use(
  config => {
    const token = _getStorage('token');
    if (token) {
      config.headers["token"] = token;
    }
    return config
  },
  err => {
    console.log('err', err)
    return Promise.reject(err)
  })
// http response 拦截器
instance.interceptors.response.use(
  response => {
    if (response.data.code === 500 || response.data.code === 601 || response.data.code === 404) {
      setTimeout(function () {
        window.location.href = "/404"
        // _removeStorage('token');
        // _removeStorage('userInfo');
      }, 1500)
    }
    if(response.data.code!==200){
      window.location.href = "/404"
      Toast.show({
        content: response.data.message,
        position:'top',
        duration:5000
      })
    }
    return response.data
  },
  //接口错误状态处理，也就是说无响应时的处理
  error => {
    return Promise.reject(error.response) // 返回接口返回的错误信息
  })
export default instance
