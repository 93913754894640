import './App.scss';

import {Component} from 'react';
import { BrowserRouter } from "react-router-dom";
import BaseRouters from "@/router/index";

// window.Buffer = window.Buffer || require("buffer").Buffer;
// window.process=window.process|| require('process').process;
export default class App extends Component{
  render(){
    return (
      <BrowserRouter>
        <BaseRouters />
      </BrowserRouter>
    );
  }
}
